<template>
  <base-section id="gallery">
    <v-container>
      <v-fade-transition
        group
        hide-on-leave
        tag="div"
        class="row"
      >
        <v-col
          v-for="(model, i) in models"
          :key="model.title"
          class="gallery-card"
          cols="12"
          sm="6"
          md="4"
        >
          <project-card
            v-bind="model"
            :src="model.src"
            height="170"
            width="100%"
          />
        </v-col>
      </v-fade-transition>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionGallery',

    components: {
      ProjectCard: () => import('@/components/ProjectCard'),
    },
    created () {
      this.models[0].title = this.$t('products.title1')
      this.models[1].title = this.$t('products.title2')
      this.models[2].title = this.$t('products.title3')
      this.models[3].title = this.$t('products.title4')
      this.models[4].title = this.$t('products.title5')
      this.models[5].title = this.$t('products.title6')
      this.models[6].title = this.$t('products.title7')
      this.models[7].title = this.$t('products.title8')

      this.$EventBus.$on('localeChanged', () => {
        this.models[0].title = this.$t('products.title1')
        this.models[1].title = this.$t('products.title2')
        this.models[2].title = this.$t('products.title3')
        this.models[3].title = this.$t('products.title4')
        this.models[4].title = this.$t('products.title5')
        this.models[5].title = this.$t('products.title6')
        this.models[6].title = this.$t('products.title7')
        this.models[7].title = this.$t('products.title8')
      })
    },
    data: () => ({
      models: [
        {
          title: 'NITRILE EXAMINATION GLOVES',
          src: require('@/assets/nitrile.jpg'),
        },
        {
          title: 'INDUSTRIAL GLOVES',
          src: require('@/assets/industrial.jpg'),
        },
        {
          title: 'LATEX GLOVES',
          src: require('@/assets/latex.jpg'),
        },
        {
          title: 'MEDICAL/SURGICAL GLOVES',
          src: require('@/assets/surgical.jpg'),
        },
        {
          title: 'HOUSEHOLD GLOVES',
          src: require('@/assets/household.jpg'),
        },
        {
          title: 'CLEANROOM GLOVES',
          src: require('@/assets/cleanroom.jpg'),
        },
        {
          title: 'SAFETY GLOVES',
          src: require('@/assets/safety.jpg'),
        },
        {
          title: 'CHEMOTHERAPY GLOVES',
          src: require('@/assets/chemotherapy.jpg'),
        },
      ],
    }),

    computed: {
      projects () {
        return [
          { title: 'Cierra Vega', subtitle: 'Wordpress' },
          { title: 'Pierre Cox', subtitle: 'Statistics' },
          { title: 'Alden Cantrell', subtitle: 'Wordpress' },
          { title: 'Kierra Gentry', subtitle: 'Branding' },
          { title: 'Thomas Crane', subtitle: 'Shopping' },
          { title: 'Miranda Shaffer', subtitle: 'Branding' },
          { title: 'Bradyn Kramer', subtitle: 'Photography' },
          { title: 'Bailey Wolfe', subtitle: 'Wordpress' },
          { title: 'Lorelei Holloway', subtitle: 'Ecommerce' },
          { title: 'Justine Elliott', subtitle: 'Ecommerce' },
        ].filter(project => {
          if (this.tab.toLowerCase() === 'all') return true

          return project.subtitle.toLowerCase() === this.tab.toLowerCase()
        })
      },
    },
  }
</script>

<style lang="sass">
  .gallery-card
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)
</style>
